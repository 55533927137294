export const configureManagementActions = (
  managementActionsBuilder: any,
  t: any,
  enableAddPortfolioWidgetButton: boolean,
) => {
  managementActionsBuilder.customActions().addAction(
    {
      title: t('Management_Actions_Add_New_Project'),
      actionId: 'addNewProject',
      icon: 'addAction',
      type: 'dashboard',
    },
    {
      title: t('Management_Actions_Manage_Projects'),
      actionId: 'manageProjects',
      icon: 'settingsAction',
      type: 'dashboard',
    },
    {
      title: t('Management_Actions_Manage_Collection'),
      actionId: 'manageCollections',
      icon: 'settingsAction',
      type: 'dashboard',
    },
    {
      title: t('Management_Actions_Manage_Pages'),
      actionId: 'managePages',
      icon: 'addAction',
      type: 'editorActions',
    },
  );
  if (enableAddPortfolioWidgetButton) {
    managementActionsBuilder.customActions().addAction({
      title: t('Management_Actions_Add_Widget_To_Page'),
      actionId: 'addWidgetToPage',
      icon: 'addAction',
      type: 'editorActions',
    });
  }

  managementActionsBuilder.learnMoreAction().set({
    id: '3478478-394839489-39849358',
  });
};
