import { getProjectElementsData } from '../components/projectPageWidget/panel/showHideData';
import { getFeaturedCollectionElementsData } from '../components/featuredCollectionWidget/panel/showHideData';
import { getCollectionElementsData } from '../components/collectionPageWidget/panel/showHideData';
import { getPortfolioElementsData } from '../components/portfolioPageWidget/panel/showHideData';
import { MainWidgetsIds } from '../constants';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { ComponentRef } from '@wix/platform-editor-sdk';
import { FeaturedCollectionWidgetWidgetProps } from '../components/featuredCollectionWidget/config/types';

export const getPanelData = async (
  t: any,
  editorSDK: FlowEditorSDK,
  currentWidgetId: string,
  widgetRef: ComponentRef,
) => {
  let panelData = {
    categoriesData: [] as any,
    elementsData: [] as any,
  };
  switch (currentWidgetId) {
    case MainWidgetsIds.PORTFOLIO_PAGE:
      panelData = getPortfolioElementsData(t);
      break;
    case MainWidgetsIds.COLLECTION_PAGE:
      panelData = getCollectionElementsData(t);
      break;
    case MainWidgetsIds.PROJECT_PAGE:
      panelData = getProjectElementsData(t);
      break;
    case MainWidgetsIds.FEATURE_COLLECTION:
      const props = (await editorSDK.application.appStudioWidgets.props.get(
        '',
        {
          widgetRef,
        },
      )) as FeaturedCollectionWidgetWidgetProps;
      const numOfSelectedCollections =
        props.data?.selectedCollections?.length || 0;
      panelData = getFeaturedCollectionElementsData(
        t,
        numOfSelectedCollections,
      );
      break;

    default:
      break;
  }
  return panelData;
};
