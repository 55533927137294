import { IHttpClient, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  createNewPortfolioApp,
  queryProjectsWithCollectionInfo,
  queryProjects,
  getProjectPageData,
} from '@wix/ambassador-portfolio-projects-v1-project/http';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import { SortOrder } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import {
  getCollectionSlugFromUrl,
  getProjectSlugFromUrl,
} from '../utils/urlsUtils';
import { listProjectItems } from '@wix/ambassador-portfolio-project-items-v1-project-item/http';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';

export const getPortfolioApi = (httpClient: IHttpClient) => {
  const notifyPortfolioAppCreation = async () => {
    try {
      const data = await httpClient.request(createNewPortfolioApp({}));
      return data;
    } catch (e) { }
  };
  return { notifyPortfolioAppCreation };
};

export const getCollections = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: false },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections;
    });
};
export const getCollectionsWithHidden = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections;
    });
};
export const getCollectionProjects = async (
  httpClient: IHttpClient,
  collectionId: string,
) => {
  return httpClient
    .request(
      queryProjectsWithCollectionInfo({
        query: {
          filter: {
            collectionId: { $eq: collectionId },
            hidden: { $eq: false },
          },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { projects = [] },
      } = res;
      return projects;
    });
};

export const getFirstCollection = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: { $eq: false } },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections[0];
    });
};

export const getCollectionBySlug = async (
  flowAPI: PlatformControllerFlowAPI,
) => {
  const collectionSlug = getCollectionSlugFromUrl(flowAPI);
  return flowAPI.httpClient
    .request(queryCollections({ query: { filter: { slug: collectionSlug } }, includePageUrl: true }))
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;

      return collections[0];
    });
};

export const getProjects = async (
  httpClient: IHttpClient,
  collectionId: string,
) => {
  return httpClient
    .request(
      queryProjectsWithCollectionInfo({
        query: {
          filter: {
            collectionId: { $eq: collectionId },
            hidden: { $eq: false },
          },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { projects = [] },
      } = res;
      return projects;
    });
};

export const getFirstProject = async (httpClient: IHttpClient) => {
  return httpClient.request(queryProjects({ query: {} })).then((res) => {
    const {
      data: { projects = [] },
    } = res;
    return { project: projects[0] };
  });
};

export const getProjectBySlug = async (flowAPI: PlatformControllerFlowAPI) => {
  const projectSlug = getProjectSlugFromUrl(flowAPI);
  const collectionSlug = getCollectionSlugFromUrl(flowAPI);
  const res = await flowAPI.httpClient.request(
    getProjectPageData({ collectionSlug, projectSlug }),
  );

  return res.data;
};
export const getProjectIdBySlug = async (
  httpClient: IHttpClient,
  projectSlug: string,
) => {
  const res = await httpClient.request(
    queryProjects({ query: { filter: { slug: projectSlug } } }),
  );
  const projects = res.data.projects || [];
  return projects[0]?.id;
};
export const getCollectionIdBySlug = async (
  httpClient: IHttpClient,
  collectionSlug: string,
) => {
  const res = await httpClient.request(
    queryCollections({ query: { filter: { slug: collectionSlug } } }),
  );
  const collections = res.data.collections || [];
  return collections[0]?.id;
};

export const getCollectionsLength = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: false },
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections?.length;
    })
    .catch((e) => {
      console.error(e);
      return 0;
    });
};

export const getProjectItems = async (
  httpClient: IHttpClient,
  projectId: string,
) => {
  const projectItems: Item[] = [];
  let hasNext = false;
  let offset = 0;
  do {
    const res = await httpClient.request(
      listProjectItems({ projectId, paging: { offset, limit: 200 } }),
    );
    const { data } = res;
    offset += data.pagingMetadataV2?.count || 200;
    hasNext = data.pagingMetadataV2?.hasNext as boolean;
    data?.items && projectItems.push(...data?.items);
  } while (hasNext);
  return projectItems;
};
