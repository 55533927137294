import { ProjectHeaderWidgetComponentIds } from '../../projectHeaderWidget/config/constants';
import { ProjectWidgetComponentIds } from '../../projectWidget1/config/constants';
import project1image from '../../../assets/media/project-page-presets/project-page-1.png';
import project2image from '../../../assets/media/project-page-presets/project-page-2.png';
import project3image from '../../../assets/media/project-page-presets/project-page-3.png';
import project4image from '../../../assets/media/project-page-presets/project-page-4.png';
import project5image from '../../../assets/media/project-page-presets/project-page-5.png';
import project6image from '../../../assets/media/project-page-presets/project-page-6.png';
import project7image from '../../../assets/media/project-page-presets/project-page-7.png';
import project8image from '../../../assets/media/project-page-presets/project-page-8.png';

export enum ProjectPageWidgetComponentIds {
  Root = '#projectPageWidgetRoot',
  ProjectWidget1 = '#projectWidget1',
  EmptyStateWidget = '#projectEmptyStateWidget1',
}

export enum ProjectPageWidgetEvents {}

export const ProjectPageWidgetTabsArray = [
  {
    label: 'Project_Design_Panel_Title',
    roles: [ProjectHeaderWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Details_Label',
    roles: ['#detailLabel'],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Details_Text',
    roles: ['#detailText'],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Description',
    roles: [ProjectHeaderWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Hero_Image',
    roles: [ProjectHeaderWidgetComponentIds.HeroImage],
  },
  // {
  //   label: 'Project_Design_Panel_Gallery',

  //   roles: [ProjectWidgetComponentIds.Gallery],
  // },
];

export const ProjectPageWidgetPresets = [
  {
    // 1.1
    id: 'externalPreset-variants-l71p97vv',
    connectedMobilePresetID: 'variants-l71pcd2c',
    src: project1image,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 2.1
    id: 'externalPreset-variants-l71toaf3',
    connectedMobilePresetID: 'variants-l71tr32a',
    src: project2image,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 3.1
    id: 'externalPreset-variants-l74qktjj',
    connectedMobilePresetID: 'variants-l71s8wjq',
    src: project3image,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 4.1
    id: 'externalPreset-variants-l71uhe49',
    connectedMobilePresetID: 'variants-l71vneg0',
    src: project4image,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 5.1
    id: 'externalPreset-variants-l71x7bcn',
    connectedMobilePresetID: 'variants-l724u4q0',
    src: project5image,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 6.1
    id: 'externalPreset-variants-l728gqx9',
    connectedMobilePresetID: 'variants-l728le0g',
    src: project6image,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 7.1
    id: 'externalPreset-variants-l72a759d',
    connectedMobilePresetID: 'variants-l72a9j26',
    src: project7image,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 8.1
    id: 'externalPreset-variants-l7366shq',
    connectedMobilePresetID: 'variants-l73bsbwq',
    src: project8image,
    layout: {
      width: '114px',
      height: '154px',
    },
  },
];
