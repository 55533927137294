import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import {
  configureWidgetTextDesignGfpp,
  customizeNativeGalleryGFPPs,
} from '../../utils/manifestUtils';
import { setConfigureConnectedComponents } from './config/utils';
import { CollectionWidgetTextComponentIds } from './config/constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Collections_Widget'),
    nickname: 'collectionWidget',
  });

  customizeNativeGalleryGFPPs({
    controllerBuilder,
    galleryRole: 'collectionGallery',
    shouldHideSettingsGfpp: true,
    t,
  });
  setConfigureConnectedComponents({ controllerBuilder, editorSDK, flowAPI });
  configureWidgetTextDesignGfpp({
    controllerBuilder,
    editorSDK,
    t,
    textComponentIds: CollectionWidgetTextComponentIds,
  });
};
