import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import {
  getRole,
  getDynamicProjectIdAndOpenDashboard,
  ProjectConfigureMainActionEditGfpp,
} from '../../../utils/manifestUtils';
import { ProjectHeaderWidgetComponentIds } from './constants';
import { PROJECT_PAGE_AUTO_FOCUS_TARGETS } from '../../../constants';

export const setConfigureConnectedComponents = ({
  controllerBuilder,
  editorSDK,
  flowAPI,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
}) => {
  const { t } = flowAPI.translations;
  const { httpClient } = flowAPI;
  ProjectConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    httpClient,
    role: getRole(ProjectHeaderWidgetComponentIds.Title),
    label: t('Project_Page_Connected_Component_Title'),
  });
  ProjectConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    httpClient,
    role: getRole(ProjectHeaderWidgetComponentIds.Description),
    label: t('Project_Page_Connected_Component_Description'),
    focusOn: PROJECT_PAGE_AUTO_FOCUS_TARGETS.DESCRIPTION,
  });

  controllerBuilder.configureConnectedComponents(
    'imageBox',
    (componentBuilder) => {
      componentBuilder
        .gfpp()
        .set('mainAction2', {
          label: t('Project_Page_Connected_Component_Cover'),
          onClick: getDynamicProjectIdAndOpenDashboard({
            editorSDK,
            httpClient,
            focusOn: PROJECT_PAGE_AUTO_FOCUS_TARGETS.COVER,
          }),
        })
        .set('design', {
          target: {
            role: getRole(ProjectHeaderWidgetComponentIds.HeroImage),
          },
        })
        .set('animation', {
          target: {
            role: getRole(ProjectHeaderWidgetComponentIds.HeroImage),
          },
        });
    },
  );
};
