import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';

export enum PortfolioPageWidgetComponentIds {
  Root = '#portfolioPageWidgetRoot',
  MultiStateWidget = '#collectionMsbWidget1',
}

export enum PortfolioPageWidgetEvents {}

export const PortfolioPageWidgetTabsArray = [
  {
    label: 'Portfolio_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Portfolio_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Portfolio_Design_Panel_Projects_Gallery',
  //   roles: [CollectionWidgetComponentIds.Gallery],
  // },
  {
    label: 'Portfolio_Design_Panel_Page_Background',
    roles: [PortfolioPageWidgetComponentIds.Root],
  },
];
